@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

$color-black: #23292d;
$color-green: #54b69e;
$color-white: #fff;

$color-header-home: #c988de;
$color-header-parent: #2de7c3;
$color-header-teen: #7cdd8c;
$color-header-dw: #0ab2fa;
$color-header-faq: #28aaf4;
$gradient-home: linear-gradient(180deg, #d47cdd 12.95%, #86cbe1 103.06%);
$gradient-parent: linear-gradient(180deg, #2de7c3 12.95%, #0abae7 103.06%);
$gradient-teen: linear-gradient(180deg, #66d39f 6.37%, #22b7db 64.85%);
$gradient-dw: linear-gradient(180deg, #0ca7ff 0%, #00ebdd 104.88%);
$gradient-faq: linear-gradient(180deg, #00cbf8 0%, #7b68ee 103.74%);

$shadow-default: 0px 6px 14px -10px rgba($color-black, 0.7);
$shadow-default-bottom: 0px -6px 14px -10px rgba($color-black, 0.7);
$font-default: "Poppins", sans-serif;

$height-header: 120px;
$width-content: 1366px;
$radius-default: 8px;

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font-default;
  margin: 0;
  font-size: 14px;
  color: $color-black;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  line-height: 1.2;
}

.header {
  background-color: #c191df;
  z-index: 9999;
  top: 0;
  position: sticky;
}

.card {
  box-shadow: $shadow-default, 0 0.5rem 3rem 0 rgba($color-black, 0.05);
}

.header .content {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  height: $height-header;

  .logo {
    margin-right: 1rem;

    img {
      height: 3rem;
    }
  }

  a:not(.logo-link) {
    color: $color-white;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    padding: 0.3rem 0.5rem;
    border-radius: 1rem;
    background: rgba($color-black, 0);
    transition: padding 0.3s, background 0.2s;

    &:hover,
    &.active {
      padding: 0.3rem 1rem;
      background: $color-black;
    }
  }

  .links-left {
    flex: 1;

    a {
      color: $color-white;
      text-decoration: none;
      font-weight: bold;
      font-size: 16px;
      padding: 0.3rem 0.5rem;
      border-radius: 1rem;
      background: rgba($color-black, 0);
      transition: padding 0.3s, background 0.2s;

      &:hover,
      &.active {
        padding: 0.3rem 1rem;
        background: $color-black;
      }
    }
  }

  h1 {
    color: white;
  }
}

.theme-card-container {
  padding: 150px 10px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(201, 137, 223, 1) 0%,
    rgba(197, 141, 223, 1) 3%,
    rgba(138, 198, 225, 1) 100%
  );
  min-height: 120vh;
  height: 100%;
  margin-top: -150px;
}

.theme-card {
  max-width: 450px;
  display: block;
  margin: 50px auto;
  padding: 10px;
  border-radius: 15px;

  .sigin-in-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
    button {
      &:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      &:nth-child(2) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
      &:hover {
        background-color: #0d6efd57;
      }
    }
  }
  section {
    display: flex;
    justify-content: center;
  }
}

.loading {
  margin-right: 8px;
  margin-top: 4px;

  &.status {
    display: block;
    margin: auto;
  }
}

.wait {
  display: flex;
  flex-direction: row;
  align-items: center;

  .loading {
    margin: 0;
  }
  h5 {
    margin-left: 10px;
    padding: 0;
  }
}

.status-container {
  text-align: center;
  h4 {
    margin: 15px;
  }
  p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

.personal-info {
  h5 {
    text-align: center;
    margin-bottom: 20px;
  }

  input.form-check-input {
    width: 20px;
    height: 20px;
  }

  label.form-check-label {
    margin-top: 4px;
    margin-left: 5px;
    a {
      color: #28aaf4;
    }
  }
}

@media screen and (max-width: 439px) {
  .header .content {
    .logo {
      margin-right: 1rem;

      img {
        height: 2rem;
      }
    }
  }
}

@media screen and (max-width: 384px) {
  .header .content {
    .logo {
      margin-right: 1rem;

      img {
        height: 1.5rem;
      }
    }
  }

  .theme-card {
    .sigin-in-options {
      button {
        font-size: 11px;
      }
    }
  }
}

button.btn.theme {
  border-radius: 50px;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem 0.5rem 0;
  font-family: $font-default;
  outline: none;
  text-decoration: none;
  background: $color-green;

  &.bg {
    min-width: 180px;
    text-align: center;
    padding: 0.7rem 2rem;
    font-size: 1rem;

    margin-top: 1rem;
    border: solid thin darken($color-green, 2);

    &:hover {
      background: lighten($color-green, 3);
    }
  }
}

.status-icon {
  height: 70px;
}
